<template>
    <div class="points" v-if="!userIsPoint">
        <v-row style="max-width: 2400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('points.list_of_points', { p1: points.length, p2: usersCount, p3: usersCountOnline }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-btn outlined small text tile class="mx-2 mt-3 flex-grow-0" @click="closeAll()">Свернуть</v-btn>
                        <v-btn outlined small text tile class="mx-2 mt-3  flex-grow-0" @click="openAll()">Развернуть</v-btn>
                        <v-btn outlined small tile color="success" class="mx-2 mt-3  flex-grow-0" @click="onClickCreate()">Создать</v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :single-expand="false"
                        show-expand
                        :headers="headers"
                        :search="search"
                        :items="points"
                        item-key="id"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @update:items-per-page="updatePaginate"
                        @update:page="updatePaginate"
                        ref="dTable"
                    >
                        <template v-slot:item="{ item, expand, isExpanded }">
                            <tr @click="ordersDialog || editFlag ? false : expand(!isExpanded)">
                                <td :colspan="headers.length - 4">
                                    <v-btn small icon :data-open="isExpanded">
                                        <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                                        <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>

                                    <v-avatar size="20" class="mx-0" v-if="item.settings.subscribe_enable">
                                        <img alt="subscribe" :src="`${ require('../../assets/images/darall-small.png') }`" />
                                    </v-avatar>
                                    
                                    <b class="ml-1">{{ item.name }}</b> - {{ item.address }}

                                    <v-chip class="mx-2 px-2" style="cursor:pointer" color="blue lighten-2" outlined label x-small>{{ item.users.length }}</v-chip>

                                    <v-btn small icon @click="onClickEdit(item)">
                                        <v-icon>mdi-home-edit-outline</v-icon>
                                    </v-btn>

                                </td>
                                <td>
                                    <span style="width:35px; display: inline-block;">{{ item.ordersToday }}</span>
                                    <v-btn v-if="item.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                                </td>
                                <td>
                                    <span style="width:35px; display: inline-block;">{{ item.ordersYesterday }}</span>
                                    <v-btn v-if="item.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                                </td>
                                <td>
                                    <span style="width:35px; display: inline-block;">{{ item.ordersMonth }}</span>
                                    <v-btn v-if="item.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item)"><v-icon small>mdi-menu</v-icon></v-btn>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <tr class="d-none">{{headers.length}}</tr>
                            <tr class="expanded-content">
                                <td></td>
                                <td></td>
                                <td>
                                    <p v-for="user in item.users" :key="user.id">
                                        <v-avatar size="24" class="mr-2">
                                            <img alt="point" :src="`${config.api.delivery + 'user_avatar?id=' + user.id}`" />
                                        </v-avatar>
                                        <b>{{ getUserDisplayName(user, UserNameDisplay.firstLastName) }}</b>
                                    </p>
                                </td>
                                <td>
                                    <p class="py-1" v-for="user in item.users" :key="user.id">
                                        <span class="account-name" v-if="item.darallAccount.login" @click="onClickAccountEdit(item, user)">{{ item.darallAccount.login }}</span>
                                        <v-btn v-if="!item.darallAccount.login" class="round-icon" outlined text rounded x-small @click="onClickAccountEdit(item, user)"><v-icon x-small>mdi-plus</v-icon></v-btn>
                                    </p>
                                </td>
                                <td>
                                    <p class="py-1" v-for="user in item.users" :key="user.id">
                                        <span :style="{ color: getConnectColor(user.telegramAccount?.isLogged) }">{{ getConnectStatus(user.telegramAccount?.isLogged) }}</span>
                                    </p>
                                </td>
                                <td>
                                    <!-- <p v-for="user in item.users" :key="user.id">
                                        <span class="py-1" style="width:35px; display: inline-block;">{{ user.ordersToday }}</span>
                                        <v-btn v-if="user.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item, user)"><v-icon small>mdi-menu</v-icon></v-btn>
                                    </p> -->
                                </td>
                                <td>
                                    <!-- <p v-for="user in item.users" :key="user.id">
                                        <span class="py-1" style="width:35px; display: inline-block;">{{ user.ordersYesterday }}</span>
                                        <v-btn v-if="user.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item, user)"><v-icon small>mdi-menu</v-icon></v-btn>
                                    </p> -->
                                </td>
                                <td>
                                    <!-- <p v-for="user in item.users" :key="user.id">
                                        <span class="py-1" style="width:35px; display: inline-block;">{{ user.ordersMonth }}</span>
                                        <v-btn v-if="user.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item, user)"><v-icon small>mdi-menu</v-icon></v-btn>
                                    </p> -->
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <div class="text-center">
            <v-dialog v-model="pointDialog" max-width="800" @click:outside="editFlag = false; createFlag = false">
                <v-card v-if="editFlag || createFlag" class="points-dialog">
                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <v-form>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="7">

                                                <v-text-field v-model="valueName" label="Наименование" hide-details></v-text-field>

                                                <v-text-field v-model="valuePhone" label="Номер телефона" hide-details></v-text-field>

                                                <v-text-field v-model="valueAddress" label="Адрес забора" hide-details></v-text-field>

                                                <v-row class="mt-0">

                                                    <v-col cols="5" class="d-flex flex-column align-center justify-center">

                                                        <v-tooltip bottom open-delay="800">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                                                    <v-btn v-if="!userIsPoint" :disabled="addressFoundFlag" :loading="addressFoundFlag" small class="ma-1 btn-address" @click="onClickAddressFound" hide-details>
                                                                        🔍 Найти место
                                                                    </v-btn>
                                                                </span>
                                                            </template>
                                                            <span>Определить место забора по строке адреса</span>
                                                        </v-tooltip>

                                                    </v-col>

                                                </v-row>

                                                <v-text-field v-if="!createFlag && $store.state.user.settings?.city === 'Владивосток'" :disabled="userIsPoint" v-model="valueDarallPoint" label="Связь с точкой Darall" hide-details></v-text-field>

                                            </v-col>

                                            <v-col cols="5" class="d-flex align-center justify-center">

                                                <address-widget
                                                    header="Укажите адрес или место забора"
                                                    status-positive="✓ Место забора определено"
                                                    status-negative="❗️ Место забора не определено"
                                                    tooltip-text="Нажмите, чтобы указать на карте место забора"
                                                    :height="210"
                                                    :read-only="userIsPoint == true"
                                                    :default-latitude="$store.state.user.city.latitude"
                                                    :default-longitude="$store.state.user.city.longitude"
                                                    :address="valueAddress"
                                                    :latitude="valueLatitude"
                                                    :longitude="valueLongitude"
                                                    @update="onAddressWidgetUpdate"
                                                ></address-widget>

                                            </v-col>

                                        </v-row>
                                    </v-col>

                                    <v-col v-if="!createFlag" cols="12" class="pb-0">
                                        <p class="my-0">Пользователи Telegram бота</p>
                                    </v-col>

                                    <v-col v-if="!createFlag" cols="12" sm="12" md="6">
                                        <v-card class="mx-auto" max-width="500">
                                            <v-toolbar color="teal" dark height="40">
                                                <v-toolbar-title>Пользователи точки</v-toolbar-title>
                                            </v-toolbar>

                                            <v-list dense>
                                                <draggable class="list-group" :list="currentPoint.users" group="people" @change="onChangeUser">
                                                    <v-list-item v-for="user in currentPoint.users" :key="user.id">
                                                        <!-- <div class="close" @click="onClickUserDisconnectPrompt(user)"></div> -->
                                                        <v-list-item-content class="d-block">
                                                            <v-avatar size="24" class="mr-2">
                                                                <img alt="point" :src="`${config.api.delivery + 'user_avatar?id=' + user.id}`" />
                                                            </v-avatar>
                                                            <b>{{ getUserFullName(user) }}</b>
                                                            <span class="ml-2" :style="{ color: getConnectColor(user.telegramAccount.isLogged) }">{{ getConnectStatus(user.telegramAccount.isLogged).toLowerCase() }}</span>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </draggable>
                                            </v-list>
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn class="mx-2" color="success" dark @click="onClickSave">{{ createFlag ? 'Создать точку' : 'Сохранить' }}</v-btn>
                        <v-btn v-if="!createFlag" class="mx-2" color="error" text @click="onClickDelete">Удалить</v-btn>
                        <v-btn
                            class="mx-2"
                            color="primary"
                            text
                            @click="
                                pointDialog = false
                                editFlag = false
                            "
                            >Закрыть</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="ordersDialog" width="720" scrollable>
                <v-card :loading="loadingOrder">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <div class="text-center">
                            <v-pagination v-model="page" :length="Math.ceil(orders.length / perPage)"></v-pagination>
                        </div>
                        <span v-if="loadingOrder && !visibleOrders.length">{{ $t('common.loading') }}</span>
                        <div v-for="(order, i) of visibleOrders" :key="order.id" class="d-flex">
                            {{ ((page-1) * perPage + i + 1) }}.
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle1(order)">{{ order.idNum }}</span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent1(currentPoint, order)"></div>
                                </v-card>
                            </v-menu>
                            <span v-html="orderListItem(order)"></span>
                            <br />
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="primary" dark @click="updateDetails">Обновить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="ordersDialog = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="accountDialog" width="500">
                <v-card :loading="loadingAccount">
                    <template slot="progress">
                        <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                    </template>

                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <v-text-field placeholder="" hide-details name="accountname" type="text" label="Логин" v-model="valueLogin" prepend-icon="mdi-account" @click:append="isShowPassword = !isShowPassword"></v-text-field>
                        <v-text-field placeholder="" hide-details name="accountpass" :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="isShowPassword ? 'text' : 'password'" label="Пароль" v-model="valuePassword" prepend-icon="mdi-key" @click:append="isShowPassword = !isShowPassword"></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="success" dark @click="onClickAccountSave">Сохранить</v-btn>
                        <v-btn v-if="accountDeleteShow" class="mx-2" color="error" text @click="onClickAccountDelete">Удалить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="accountDialog = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deletePointPrompt" width="500">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        Удаление точки {{ currentPoint.name }}
                    </v-card-title>

                    <v-card-text class="py-4">
                        <p>Выберите точку, куда нужно перенести заказы и пользователей после удаления точки <b>{{ currentPoint.name }}</b></p>
                        
                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" :items="pointsFiltered" name="point" item-text="name" item-value="id" v-model="pointTransferId" label="Выбрать точку" hide-details></v-select>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!pointTransferId" class="mx-2" color="error" text @click="onClickDeleteTransfer">Удалить и перенести</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="deletePointPrompt = false">Закрыть</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="userDisconnectPrompt" width="500">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        Отключение пользователя
                    </v-card-title>

                    <v-card-text class="py-4">
                        <p>Действительно отключить пользователя точки <b>"{{ getUserFullName(currentUser) }}"</b> от бота?</p>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="mx-2" color="primary" dark @click="onClickUserDisconnect">Отключить</v-btn>
                        <v-btn class="mx-2" color="primary" text @click="userDisconnectPrompt = false">Отмена</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <span class="pb-16 d-block" />

    </div>
</template>
<script>
    import config from '../../config'
    import draggable from 'vuedraggable'
    import moment from 'moment-timezone'
    import addressWidget from '../address_input/Widget.vue'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import Notify from '../../mixins/Notify.js'

    export default {
        mixins: [DateTime, Status, Orders, Notify],

        data() {
            return {
                isFoundCoordinates: false,
                config,
                loading: false,
                loadingOrder: false,
                search: '',
                points: [],
                point_users: [],
                usersCount: 0,
                usersCountOnline: 0,
                pointDialog: false,
                ordersDialog: false,
                dialogHeader: '',
                dialogContent: '',
                headers: [
                    {
                        text: 'Название и адрес',
                        align: ' th-first-item',
                        value: 'name',
                    },
                    {
                        align: '1 d-none',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        align: '1 d-none',
                        value: 'address',
                        sortable: false,
                    },
                    {
                        text: 'Имя в Telegram',
                        value: 'full_name',
                        sortable: false,
                    },
                    {
                        text: 'Аккаунт',
                        value: 'name',
                        sortable: true,
                    },
                    {
                        text: 'Подключение',
                        value: 'enable',
                        sortable: false,
                    },
                    {
                        text: 'Заказы сегодня',
                        value: 'ordersToday',
                    },
                    {
                        text: 'Заказы вчера',
                        value: 'ordersYesterday',
                    },
                    {
                        text: 'Заказы за 30 дней',
                        value: 'ordersMonth',
                    },
                ],
                editFlag: false,
                addressFoundFlag: false,
                createFlag: false,
                dateStart: '',
                dateEnd: '',
                orders: [],
                page: 1,
                perPage: config.paginateItemsCount,
                userIsPoint: false,
                accountDialog: false,
                isShowPassword: false,
                valueName: '',
                valueAddress: '',
                valueLatitude: 0,
                valueLongitude: 0,
                valueLogin: '',
                valuePassword: '',
                valueLoginOld: '',
                valuePasswordOld: '',
                valuePhone: '',
                valueDarallPoint: '',
                valuePosition: null,
                currentUser: {},
                loadingAccount: false,
                accountDeleteShow: false,
                currentPoint: {},
                deletePointPrompt: false,
                pointTransferId: null,
                multiOrders: [],
                userDisconnectPrompt: false
            }
        },

        components: {
            draggable,
            addressWidget,
        },

        mounted() {
            console.log('points mount')

            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            if (!this.userIsPoint) {
                this.updateData()
            }
        },

        beforeDestroy() {},

        destroyed() {},

        watch: {},

        computed: {
            visibleOrders() {
                const allTypesOrders = [...this.orders, ...this.multiOrders]
                return allTypesOrders.slice((this.page - 1) * this.perPage, this.page * this.perPage)
            },
            pointsFiltered() {
                return this.points.filter(p => p.id != this.currentPoint.id)
            },
        },

        methods: {
            getUserFullName(user) {
                const fullName = this.getUserDisplayName(user, this.UserNameDisplay.firstLastName)
                return fullName > 30 ? fullName.substring(0, 30) + '...' : fullName
            },

            updatePaginate() {},

            onClickAccountEdit(point, user) {
                this.currentUser = user
                this.currentPoint = point

                const userName = this.getUserDisplayName(this.currentUser, this.UserNameDisplay.firstLastName)

                if (point.darallAccount) {
                    this.dialogHeader = `Доступ - ${userName}`
                    this.valueLogin = point.darallAccount.login ?? ''
                    this.valuePassword = point.darallAccount.password ?? ''
                    this.accountDeleteShow = true
                } else {
                    this.dialogHeader = `Новый доступ - ${userName}`
                    this.valueLogin = ''
                    this.valuePassword = ''
                    this.accountDeleteShow = false
                }
                this.accountDialog = true
            },

            onClickAccountSave() {
                if (this.valueLogin) {
                    this.valueLogin = this.valueLogin.trim() || null
                }
                if (this.valuePassword) {
                    this.valuePassword = this.valuePassword.trim() || null
                }

                this.loadingAccount = true
                this.$store
                    .dispatch('point_auth', {
                        pointId: this.currentUser.pointId,
                        login: this.valueLogin,
                        password: this.valuePassword,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.notifyToast('Аккаунт изменен успешно.', {color:'success', timeout: 4000})
                            this.currentPoint.darallAccount.login = this.valueLogin
                            this.currentPoint.darallAccount.password = this.valuePassword
                        } else {
                            this.notifyToast('Ошибка сохранения аккаунта.', {color:'error', timeout: 4000})
                        }
                        this.loadingAccount = false
                        this.accountDialog = false
                    })
                    .catch(err => {
                        console.log('account_set error', err)
                        this.notifyToast('Ошибка сохранения аккаунта. ' + err, {color:'error', timeout: 4000})
                        this.loadingAccount = false
                        this.accountDialog = false
                    })
            },

            onClickAccountDelete() {
                this.valueLogin = null
                this.valuePassword = null
                this.accountDelete()
            },

            onClickTodayDetails(point) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${point.name} - заказы сегодня (${point.ordersToday})`
                this.currentPoint = point
                this.dateStart = this.getDate1String()
                this.dateEnd = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },

            onClickYesterdayDetails(point) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${point.name} - заказы вчера (${point.ordersYesterday})`
                this.currentPoint = point
                this.dateStart = this.getDate1String(-1)
                this.dateEnd = this.getDate2String(-1)
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },

            onClickMonthDetails(point) {
                this.page = 1
                this.orders = []
                this.dialogHeader = `${point.name} - заказы за 30 дней (${point.ordersMonth})`
                this.currentPoint = point
                this.dateStart = this.getDate1String(-29)
                this.dateEnd = this.getDate2String()
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },

            updateDetails() {
                this.loadingOrder = true

                this.$store
                    .dispatch('order_list', {
                        dateStart: this.dateStart,
                        dateEnd: this.dateEnd,
                        pointId: this.currentPoint.id,
                        isSortByDelivery: true,
                        isReversed: true,
                        isDeleted: false,
                        statuses: [
                            this.OrderStatus.new,
                            this.OrderStatus.passed,
                            this.OrderStatus.onWayPoint,
                            this.OrderStatus.onWayCustomer,
                            this.OrderStatus.delivered,
                        ],
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            this.orders = res.data.orders
                            this.multiOrders = []
                        } else {
                            this.dialogContent = this.$t('common.loading_error', { p1: 1 })
                        }
                        this.loadingOrder = false
                    })
                    .catch(err => {
                        this.loadingOrder = false
                        this.dialogContent = this.$t('common.loading_error', { p1: 2 })
                    })
            },

            updateData() {
                this.loading = true
                this.$store
                    .dispatch('point_list', { additionalInfo: true })
                    .then(res => {
                        if (res.data.success) {
                            const year = this.getDate().year()
                            const month = this.getDate().month()
                            const today = this.getDate().date()
                            const yesterday = this.getDate().subtract(1, 'days').date()

                            this.points = res.data.points.map(point => {
                                let ordersToday = 0
                                let ordersYesterday = 0
                                let ordersMonth = 0
                                
                                if (point.ordersCreateDays[year] && point.ordersCreateDays[year][month]) {
                                    if (point.ordersCreateDays[year][month][today]) {
                                        ordersToday = point.ordersCreateDays[year][month][today]
                                    }
                                    if (point.ordersCreateDays[year][month][yesterday]) {
                                        ordersYesterday = point.ordersCreateDays[year][month][yesterday]
                                    }
                                }

                                for (let i = 0; i < 30; i += 1) {
                                    const year = this.getDate().subtract(i, 'days').year()
                                    const month = this.getDate().subtract(i, 'days').month()
                                    const date = this.getDate().subtract(i, 'days').date()

                                    if (point.ordersCreateDays[year] && point.ordersCreateDays[year][month] && point.ordersCreateDays[year][month][date]) {
                                        ordersMonth += point.ordersCreateDays[year][month][date]
                                    }
                                }
                                return {
                                    ...point,
                                    ordersToday,
                                    ordersYesterday,
                                    ordersMonth,
                                }
                            })
                        } else {
                            this.notifyToast('Не удалось загрузить список точек', { color: 'error', timeout: 4000 })
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                        this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 4000 })
                    })
            },

            orderListItem(order) {
                return ` <b>${this.getOrderName(order, this.currentPoint)}</b> - ${moment(order.createdAt).format('D MMM HH:mm')} - <span style="color: ${this.orderStatusColorHtml1(order.status)};">${this.orderStatusStr1(order.status)}${order.dateComplete ? ` (${moment(order.dateComplete).format('D MMM HH:mm')})` : ''}</span><br>`
            },

            onChangeUser() {},

            onClickSave() {
                if (this.valueName === '') {
                    this.notifyToast('Введите наименование точки', {color:'error', timeout: 4000})
                    return
                }
                if (this.valueAddress === '') {
                    this.notifyToast('Введите адрес забора', {color:'error', timeout: 4000})
                    return
                }
                if (!this.isFoundCoordinates) {
                    this.notifyToast('Укажите место забора у точки', {color:'error', timeout: 4000})
                    return
                }

                this.loading = true
                this.editFlag = false

                if (this.createFlag) {

                    this.$store
                        .dispatch('point_create_auto', {
                            point: {
                                name: this.valueName,
                                address: this.valueAddress,
                                phone: this.valuePhone,
                                latitude: +this.valueLatitude,
                                longitude: +this.valueLongitude,
                            },
                            pointSettings: {
                                darallPointIdNum: +this.valueDarallPoint || null,
                            },
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.notifyToast('Точка создана успешно', {color:'success', timeout: 4000})
                                this.points.push(res.data.point)
                                this.points = this.points.sort(this.sortPoints)
                                this.pointDialog = false
                                this.createFlag = false
                            } else {
                                this.notifyToast('Ошибка создания точки', {color:'error', timeout: 4000})
                            }
                            this.loading = false
                        })
                        .catch(err => {
                            this.notifyToast('Ошибка создания точки', {color:'error', timeout: 4000})
                            this.loading = false
                        })
                } else {
                    this.$store
                        .dispatch('point_update', {
                            ...this.currentPoint,
                            settings: undefined,
                            name: this.valueName,
                            address: this.valueAddress,
                            phone: this.valuePhone,
                            latitude: +this.valueLatitude,
                            longitude: +this.valueLongitude,
                            pointSettings: {
                                ...this.currentPoint.settings,
                                darallPointIdNum: +this.valueDarallPoint || null,
                            }
                        })
                        .then(res => {
                            if (res.data.success) {
                                this.notifyToast('Точка обновлена успешно', {color:'success', timeout: 4000})
                                this.currentPoint.name = res.data.point.name
                                this.currentPoint.address = res.data.point.address
                                this.currentPoint.phone = res.data.point.phone
                                this.currentPoint.latitude = res.data.point.latitude
                                this.currentPoint.longitude = res.data.point.longitude
                                this.currentPoint.settings = res.data.point.settings
                                this.pointDialog = false
                            } else {
                                this.notifyToast('Ошибка обновления точки', {color:'error', timeout: 4000})
                                console.log('update point failure', res.data.msg)
                            }
                            this.loading = false
                        })
                        .catch(err => {
                            this.notifyToast('Ошибка обновления точки', {color:'error', timeout: 4000})
                            this.loading = false
                        })
                }
            },

            onClickDeleteTransfer() {
                this.loading = true
                this.pointDialog = false
                this.deletePointPrompt = false
                this.editFlag = false
                this.$store
                    .dispatch('point_delete', {
                        pointId: this.currentPoint.id,
                        transferPointId: this.pointTransferId,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.notifyToast('Точка удалена успешно', { color: 'success', timeout: 4000 })
                            this.updateData()
                        } else {
                            this.notifyToast('Не удалось удалить точку', { color: 'error', timeout: 4000 })
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 4000 })
                        this.loading = false
                    })
            },

            onClickDelete() {
                this.deletePointPrompt = true
                this.pointTransferId = null
            },

            onClickEdit(point) {
                this.currentPoint = point

                this.valueName = this.currentPoint.name
                this.valuePhone = this.currentPoint.phone
                this.valueAddress = this.currentPoint.address
                this.valueLatitude = this.currentPoint.latitude
                this.valueLongitude = this.currentPoint.longitude
                this.valueDarallPoint = this.currentPoint.settings.darallPointIdNum

                this.editFlag = true
                this.dialogHeader = `Точка ${this.currentPoint.name} - ${this.currentPoint.idNum}`
                this.pointDialog = true
            },

            onClickCreate() {
                this.currentPoint = {}

                this.valueName = ''
                this.valuePhone = ''
                this.valueAddress = ''
                this.valueLatitude = 0
                this.valueLongitude = 0
                this.valueDarallPoint = ''

                this.createFlag = true
                this.dialogHeader = `Новая точка`
                this.pointDialog = true
            },

            onClickAddressFound() {
                this.addressFoundFlag = true
                this.$store
                    .dispatch('address_coordinates', this.valueAddress)
                    .then(res => {
                        if (res.data.success) {
                            this.valueLatitude = res.data.latitude
                            this.valueLongitude = res.data.longitude

                            this.notifyToast('Адрес места забора найден', { color: 'success', timeout: 4000 })
                        } else {
                            this.notifyToast('Не удалось найти адрес, попробуйте ввести по-другому или укажите на карте', { color: 'error', timeout: 4000 })
                        }
                        this.addressFoundFlag = false
                    })
                    .catch(err => {
                        this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 4000 })
                        this.addressFoundFlag = false
                    })
            },

            onClickUpdate() {
                this.updateData()
            },

            onClickUserDisconnectPrompt(user) {
                this.currentUser = user
                this.userDisconnectPrompt = true
            },

            onClickUserDisconnect() {
                this.loading = true

                this.$store
                    .dispatch('user_update_telegram', {
                        ...this.currentUser,
                        pointId: null,
                        role: 'none',
                        telegramAccount: {
                            status: 'auth',
                            statusPrevious: this.currentUser.telegramAccount.status,
                            isLogged: false,
                            logoutAt: new Date(),
                        },
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.notifyToast('Пользователь отключен успешно', { color: 'success', timeout: 4000 })
                            const userIndex = this.currentPoint.users.findIndex(user => user.id === this.currentUser.id)
                            if (userIndex !== -1) this.currentPoint.users.splice(userIndex, 1)
                        } else {
                            console.error('disconnect failure', res.data.msg)
                            this.notifyToast('Не удалось отключить пользователя: ' + res.data.msg, { color: 'error', timeout: 4000 })
                        }
                        this.loading = false
                        this.userDisconnectPrompt = false
                    })
                    .catch(err => {
                        this.loading = false
                        this.userDisconnectPrompt = false
                        this.notifyToast('Ошибка: ' + err.message, { color: 'error', timeout: 4000 })
                    })
            },

            onAddressWidgetUpdate(data) {
                this.valueAddress = data.address
                this.valueLatitude = data.latitude
                this.valueLongitude = data.longitude
                this.isFoundCoordinates = data.isFoundCoordinates
            },

            getConnectColor(status) {
                return status == 1 ? '#4CAF50' : '#F44336'
            },
            getConnectStatus(status) {
                return status == 1 ? 'Подключен' : 'Отключен'
            },
            openAll() {
                for (const point of this.points) {
                    this.$set(this.$refs.dTable.expansion, point.id, true)
                }
            },
            closeAll() {
                for (const point of this.points) {
                    this.$set(this.$refs.dTable.expansion, point.id, false)
                }
            },
            sortPoints(a, b) {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                if (a.address > b.address) {
                    return 1
                }
                if (a.address < b.address) {
                    return -1
                }
                return 0
            },
            sortUsers(a, b) {
                if (a.full_name > b.full_name) {
                    return 1
                }
                if (a.full_name < b.full_name) {
                    return -1
                }
                return 0
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .btn-address {
        height: 24px !important;
    }
    .points
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                .v-data-table__expanded__row:hover
                    background #e4e4e4 !important
                    cursor pointer !important

                td
                    white-space nowrap
                    font-size 0.75rem
                    padding 0 8px
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            width 30%

        b
            font-weight 500

    .points-dialog
        b
            font-weight 500
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    padding: 0
                    cursor pointer
                    .v-list-item__content
                        padding 0 16px
                    .close
                        position: absolute;
                        opacity: 0;
                        width: 100%;
                        height: 42px;
                        -webkit-transition: opacity 350ms;
                        cursor: pointer;
                        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAUlJREFUSIntkj1rAkEQhp+5BU8LSzksgqA/4QILIknKEPJ3A0kZ8IMr/AH5QJAUctgGzB65nTSx0TvdVAHxLXffeZ9hZuCsk5fsPijIx3D4DLTisrxPsiw/FJBbm7goehD4vJjNbnb/oxpqW+DSGTNZp2m3Lnydpl1nzEREUoV2lWcPIKCNsrxVWAgMNnE8zq1NqjrfxPFYYKCwiL2/q2m2Wrm1iTNmKtBXWMRlOdyO69BfMKAuCCA0/ChgF+JVlxGASC8kPAiwhXxFURaJ9FAFWDa8t8fCqbuiWqMqiOBDi0IA2xEh0gOWXnUZifScMdOq6/oTYHfJDe9t03v7e8L9EMj/nGlIQChkb0QKUhjzJNBH9a3l3KiqMMmyvOXcCNV3gX5hzGNVs5U7UNVv4KVZFNed+XxV5QHozOerZlFcAa+oFnW+s05cP3Etzn/1OHm5AAAAAElFTkSuQmCC") no-repeat;
                        background-size: 20px;
                        background-position: right;
                        background-position-x: calc(100% - 12px);
                        z-index 100
                    .close:hover
                        opacity 1
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige

    .round-icon
        min-width 20px !important
        width 20px
        height 20px

    .account-name
        cursor pointer
        &:hover
            font-weight 500

</style>
